<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item
                :to="{ name: 'intentoDashboard' }"
                v-if="isIntentoUser"
            >
                <b-icon
                    icon="house-fill"
                    scale="1.25"
                    shift-v="1.25"
                    aria-hidden="true"
                ></b-icon>
                Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item
                :to="{ name: 'customerDashboard' }"
                v-if="isIntentoSales"
            >
                Customer Management
            </b-breadcrumb-item>
            <b-breadcrumb-item
                :to="{ name: 'customerSites' }"
                v-if="isUserAuthorized"
            >
                {{ customer.company }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'siteLicenses' }">
                {{ site.name }}
            </b-breadcrumb-item>
        </b-breadcrumb>

        <b-container fluid>
            <b-card>
                <b-card-title>
                    {{ site.name }}
                </b-card-title>

                <b-card-body>
                    <b-row>
                        <b-col cols="8"></b-col>
                        <b-col cols="4">
                            <b-button
                                block
                                v-if="isUserAuthorized"
                                variant="outline-primary"
                                v-b-modal.modal-edit-site
                                @click.prevent="populateEditModel()"
                            >
                                <b-icon-pencil /> Edit Site Data
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-card class="mt-3">
                        <b-nav tabs>
                            <b-nav-item
                                :to="{ name: 'siteStatistics' }"
                                exact
                                exact-active-class="active"
                                >Statistics</b-nav-item
                            >
                            <b-nav-item
                                :to="{ name: 'siteLicenses' }"
                                exact
                                exact-active-class="active"
                                >Licenses</b-nav-item
                            >
                            <b-nav-item
                                :to="{ name: 'siteUsers' }"
                                exact
                                exact-active-class="active"
                                >Site Admins</b-nav-item
                            >
                            <b-nav-item
                                :to="{ name: 'siteDevices' }"
                                exact
                                exact-active-class="active"
                                >Devices Seen</b-nav-item
                            >
                            <b-nav-item
                                :to="{ name: 'siteTablets' }"
                                exact
                                exact-active-class="active"
                                >Tablets Seen</b-nav-item
                            >
                        </b-nav>
                        <router-view />
                    </b-card>
                </b-card-body>
            </b-card>
        </b-container>

        <b-modal
            id="modal-edit-site"
            @ok="saveSite"
            ok-title="Save Site"
            :title="'Edit Site'"
            size="lg"
        >
            <form @submit.prevent="saveSite">
                <b-row>
                    <b-col>
                        <b-form-group label="Site">
                            <b-form-input
                                type="text"
                                v-model="siteEditModel.name"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
            <div class="form-group">
                <div v-if="errorMessage" class="alert alert-danger" role="alert">
                    {{ errorMessage }}
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatSeconds } from "@/components/utils.js";
import ApiService from "@/services/api.service";


export default {
    name: "Site",
    props: {
        customerId: {
            type: [String, Number],
            required: true,
        },
        siteId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            site: {},
            siteEditModel: {},
            customer: {},
            errorMessage: ''
        };
    },
    async created() {
        this.refreshSite();
    },
    computed: {
        ...mapGetters("auth", [
            "isIntentoSales",
            "isIntentoUser",
            "isCustomerUser",
            "getCustomerIdOfAccountManager",
        ]),
    },
    methods: {
        isUserAuthorized() {
            return this.isIntentoSales || 
            (this.isCustomerUser && this.customerIdOfAccountManager == this.$parent.customerId)
        },
        async refreshSite() {
            
            this.loading = true;

            if (this.isUserAuthorized) {
                this.customer = await ApiService.getCustomerAccount(
                    this.customerId
                );
            }
            this.site = await ApiService.getSite(this.siteId);

            this.loading = false;
        },
        async saveSite(event) {
            event.preventDefault();
            await ApiService.updateSite(this.site.id, this.siteEditModel).then(
                            () => { 
                                this.site = Object.assign({}, this.siteEditModel); // reset form
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-edit-site");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
        },
        getActiveStimulationTimeString(seconds) {
            return formatSeconds(seconds);
        },
        populateEditModel() {
            this.siteEditModel = Object.assign({}, this.site);
            this.errorMessage = '';
        }
    },
};
</script>

<style scoped>
.active {
    background: #20c997 !important;
    color: white !important;
}
</style>
